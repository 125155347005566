<template>
    <sidebar
        ref="sidebar"
        :title="sidebar.title"
        :links="sidebar.children"
        class="desktopSidebar"
    />
</template>

<script>
import Sidebar from "components/sidebar";

export default {
    name: "OperationsSidebar",
    data() {
        return {
            partnerSideBarGroup: {
                title: "Partner",
                children: [
                    {
                        to: { name: "partners" },
                        display: "Partner List",
                    },
                ],
            },
            usersSideBarGroup: {
                title: "User",
                children: [
                    {
                        to: { name: "users" },
                        display: "User List",
                    },
                ],
            },
            deliverySideBarGroup: {
                title: "Delivery",
                children: [
                    {
                        to: {
                            name: "airport-delivery-partner",
                            params: {
                                env: 'stage',
                            }
                        },
                        display: "Airport Delivery",
                    },
                ],
            },
            messagingSideBarGroup: {
                title: "Messaging",
                children: [{ to: { name: "messaging" }, display: "Messaging" },
                    { to: { name: "receipts" }, display: "Receipts" },
                ],
            },
            configsSideBarGroup: {
                title: "Configs",
                children: [{to: {name: "configs"}, display: "Configs"},
                    {to: {name: "styles"}, display: "Styles"},
                    {to: {name: "resources"}, display: "Resources"},
                    {to: {name: "svgassets"}, display: "Tabletop Logos"}]
            },
        };
    },
    computed: {
        sidebar() {
            return {
                title: "Operations",
                children: [
                    //this.partnerSideBarGroup,
                    this.usersSideBarGroup,
                    this.deliverySideBarGroup,
                    // this.messagingSideBarGroup,
                    this.configsSideBarGroup,
                ],
            };
        },
    },

    components: {
        Sidebar,
    },
}
</script>

<style scoped>

</style>
